import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import { initClient, signIn, signOut, listFilesInFolder, getSheetData } from './googleAuth';
import LoadingScreen from './LoadingScreen';
import { AnimatePresence, motion } from 'framer-motion';
import './App.css';
import { BiFoodTag } from "react-icons/bi";
import ListView from './ListView';

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [sheetData, setSheetData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [activeTab, setActiveTab] = useState('default');

  const spinnerVariants = {
    animate: {
      rotate: [0, 360],
      transition: {
        repeat: Infinity,
        duration: 1,
        ease: "linear"
      }
    }
  };

  useEffect(() => {
    const initializeGapi = async () => {
      try {
        await initClient();
        const authInstance = gapi.auth2.getAuthInstance();
        if (authInstance) {
          setIsInitialized(true);
          const storedAuth = JSON.parse(localStorage.getItem('auth'));
          if (storedAuth && authInstance.isSignedIn.get()) {
            setIsSignedIn(true);
            fetchFiles();
          } else {
            setIsLoading(false);
          }
          authInstance.isSignedIn.listen(setIsSignedIn);
        } else {
          throw new Error('Google Auth instance not initialized');
        }
      } catch (error) {
        console.error('Error initializing Google API client:', error);
        setIsLoading(false);
      }
    };
    initializeGapi();
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      fetchFiles();
    }
  }, [isSignedIn]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleSignIn = async () => {
    if (!isInitialized) {
      console.error('Google Auth instance not initialized');
      return;
    }
    try {
      await signIn();
      setIsSignedIn(true);
      fetchFiles();
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const handleSignOut = async () => {
    if (!isInitialized) {
      console.error('Google Auth instance not initialized');
      return;
    }
    try {
      await signOut();
      setIsSignedIn(false);
      setSheetData([]);
      setSearchTerm('');
      setFilteredNames([]);
      setSelectedName(null);
      setIsLoading(true);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const fetchFiles = async () => {
    try {
      const fetchedFiles = await listFilesInFolder('June');
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();
      const todayFormatted = `${day}-${month}-${year}`;
      const todaySheet = fetchedFiles.find(file => file.name.includes(todayFormatted));
      if (todaySheet) {
        setDate(todaySheet.name.split(' ')[0]);
        const data = await getSheetData(todaySheet.id, todaySheet.name);
        if (data) {
          setSheetData(data.slice(2));
          setIsLoading(false);
        } else {
          setSheetData([]);
          setIsLoading(false);
          console.error('No data found for today\'s sheet');
        }
      } else {
        setSheetData([]);
        setIsLoading(false);
        console.error('No sheet found for today');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching files:', error);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    if (term.length > 0) {
      const filtered = sheetData.filter(row => row[1] && row[1].toLowerCase().includes(term.toLowerCase()));
      setFilteredNames(filtered);
    } else {
      setFilteredNames([]);
    }
  };

  const handleSelectName = (name) => {
    setSelectedName(name);
    setSearchTerm('');
    setFilteredNames([]);
  };

  const getBgColor = (value) => {
    return value === 'OFF' ? 'bg-red-700' : 'bg-green-700';
  };

  const getPreferenceColor = (value) => {
    if (value === 'Veg') return 'text-green-500';
    if (value === 'Non Veg') return 'text-red-500';
    if (value === 'Base') return 'text-white';
  };

  const getSectionStyle = (section) => {
    const hour = currentTime.getHours();
    if (section === 'Breakfast' && hour >= 7 && hour < 11) {
      return 'py-4 text-xl shadow-2xl border-2 border-white';
    }
    if (section === 'Lunch' && hour >= 11 && hour < 15) {
      return 'py-4 text-xl shadow-2xl border-2 border-white';
    }
    if (section === 'Dinner' && hour >= 18 && hour < 23) {
      return 'py-4 text-xl shadow-2xl border-2 border-white';
    }
    return {};
  };

  const getBackgroundColor = () => {
    const hour = currentTime.getHours();
    if (selectedName) {
      if (hour >= 7 && hour < 11 && selectedName[2] === 'OFF') {
        return 'bg-red-100';
      }
      if (hour >= 11 && hour < 15 && selectedName[3] === 'OFF') {
        return 'bg-red-100';
      }
      if (hour >= 18 && hour < 23 && selectedName[4] === 'OFF') {
        return 'bg-red-100';
      }
    }
    return 'bg-green-100';
  };

  const renderDefaultView = () => (


    
    <>
      <div className="mx-10 mb-4">
        <input
          type="text"
          className="w-full p-2 border"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearch}
        />
        {filteredNames.length > 0 ? (
          <ul className="mt-2 overflow-y-auto border max-h-48">
            {filteredNames.map((name, index) => (
              <li
                key={index}
                className="p-2 cursor-pointer hover:bg-gray-200"
                onClick={() => handleSelectName(name)}
              >
                {name[1]}
              </li>
            ))}
          </ul>
        ) : (
          searchTerm && (
            <div className="mt-10 text-xl font-bold text-center text-red-500">
              Preference Not filled for {searchTerm}
            </div>
          )
        )}
      </div>
      {selectedName && (
        <div className={`my-5 p-4 ${getBackgroundColor()} shadow-2xl rounded-md flex flex-col mx-10`}>
          <h2 className="mb-4 text-xl font-bold text-center">{selectedName[1]}</h2>
          <motion.div
            className={`text-center py-2 rounded-md my-3 text-white ${getBgColor(selectedName[2])} ${getSectionStyle('Breakfast')}`}
          >
            <strong>Breakfast:</strong> {selectedName[2]}
          </motion.div>
          <motion.div
            className={`text-center py-2 rounded-md my-3 text-white ${getBgColor(selectedName[3])} ${getSectionStyle('Lunch')}`}
          >
            <strong>Lunch:</strong> {selectedName[3]}
          </motion.div>
          <motion.div
            className={`text-center py-2 rounded-md my-3 text-white ${getBgColor(selectedName[4])} ${getSectionStyle('Dinner')}`}
          >
            <strong>Dinner:</strong> {selectedName[4]}
          </motion.div>
          <div className={`text-xl text-center py-2 ${getBackgroundColor()} rounded-md my-4 flex gap-1 justify-center`}>
            <strong>{selectedName[5]}</strong>
            <BiFoodTag className={`text-3xl ${getPreferenceColor(selectedName[5])}`} />
          </div>
        </div>
      )}
    </>
  );

  const renderListView = () => (
    <ListView sheetData={sheetData} />
  );

  // Calculate total headcounts
  const totalBreakfastOn = sheetData.filter(row => row[2] !== 'OFF').length;
  const totalLunchOn = sheetData.filter(row => row[3] !== 'OFF').length;
  const totalDinnerOn = sheetData.filter(row => row[4] !== 'OFF').length;

  const renderHeadcountInfo = () => {
    const hour = currentTime.getHours();
    if (hour >= 6 && hour < 11) {
      return <h2 className="mb-2 text-lg font-bold">Headcounts Breakfast: {totalBreakfastOn}</h2>;
    }
    if (hour >= 11 && hour < 18) {
      return <h2 className="mb-2 text-lg font-bold">Headcounts Lunch: {totalLunchOn}</h2>;
    }
    if (hour >= 18 && hour < 23) {
      return <h2 className="mb-2 text-lg font-bold">Headcounts Dinner: {totalDinnerOn}</h2>;
    }
    return null;
  };

  return (


    <div className={`flex flex-col h-screen`}>
      <AnimatePresence>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <motion.div
            className="container flex flex-col items-center flex-grow mx-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <h1 className="w-screen py-4 text-2xl font-bold text-center text-white shadow-md bg-cyan-900">Students Mess</h1>
            {date && sheetData.length > 0 ? (
            <div className='flex flex-col w-screen pt-2 mx-4 mb-4 text-center bg-gray-100 rounded-md shadow-xl'>
              <h2 className="text-lg font-bold">Preferences for</h2>
              <h1 className="text-xl font-bold">{date}</h1>
              <h2 className="mb-4 text-lg font-bold">{renderHeadcountInfo()}</h2>
            </div>
            ):( 
              <motion.div
              className="w-6 h-6 py-5 mt-4 border-4 border-t-4 border-gray-500 rounded-full border-t-cyan-400"
              variants={spinnerVariants}
              animate="animate"
            />
            
            )}
            {!isSignedIn ? (
              <button
                onClick={handleSignIn}
                className="flex px-4 py-2 text-white rounded bg-cyan-900"
              >
                Sign In
              </button>
            ) : (
              <div className="w-screen">
                <div className="mt-4">
                  <div className="flex justify-center mx-6 mb-4">
                    <button
                      onClick={() => setActiveTab('default')}
                      className={`py-2 w-1/2 rounded-l ${activeTab === 'default' ? 'bg-cyan-900 text-white' : 'bg-gray-200 text-gray-600'}`}
                    >
                      Main View
                    </button>
                    <button
                      onClick={() => setActiveTab('list')}
                      className={`py-2 w-1/2 rounded-r ${activeTab === 'list' ? 'bg-cyan-900 text-white' : 'bg-gray-200 text-gray-600'}`}
                    >
                      List View
                    </button>
                  </div>
                  {activeTab === 'default' ? renderDefaultView() : renderListView()}
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {!isLoading && (
        <div>
          <div className="flex flex-col items-center mx-10">
            <button
              onClick={handleSignOut}
              className="px-4 py-2 text-white bg-gray-500 rounded-md"
            >
              Sign Out
            </button>
          </div>
          <footer className="py-4 mb-10 font-bold text-center text-gray-400">
            Developed by <a href="https://bineet.dev">Bineet</a>
          </footer>
        </div>
      )}
    </div>
  );
};

export default App;
