import { gapi } from 'gapi-script';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const preferenceFolderID = process.env.REACT_APP_FOLDER_ID;
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
];
const SCOPES = 'https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.readonly';

// Function to initialize the Google API client
export const initClient = () => {
  return new Promise((resolve, reject) => {
    console.log('Loading gapi client...');
    gapi.load('client:auth2', () => {
      console.log('Initializing gapi client...');
      gapi.auth2.init({
        client_id: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      }).then(() => {
        console.log('gapi client initialized.');
        // Load the Drive API explicitly
        gapi.client.load('drive', 'v3', () => {
          console.log('Drive API loaded.');
          gapi.client.load('sheets', 'v4', () => {
            console.log('Sheets API loaded.');
            resolve();
          });
        });
      }).catch((error) => {
        console.error('Error initializing gapi client:', error);
        reject(error);
      });
    });
  });
};

// Function to sign in the user and save the auth state in local storage
export const signIn = async () => {
  const authInstance = gapi.auth2.getAuthInstance();
  if (authInstance) {
    console.log('Signing in...');
    const user = await authInstance.signIn();
    localStorage.setItem('auth', JSON.stringify(user.getAuthResponse()));
    return user;
  } else {
    console.error('Google Auth instance not initialized');
    return Promise.reject(new Error('Google Auth instance not initialized'));
  }
};

// Function to sign out the user and clear the auth state from local storage
export const signOut = async () => {
  const authInstance = gapi.auth2.getAuthInstance();
  if (authInstance) {
    console.log('Signing out...');
    await authInstance.signOut();
    localStorage.removeItem('auth');
    return;
  } else {
    console.error('Google Auth instance not initialized');
    return Promise.reject(new Error('Google Auth instance not initialized'));
  }
};

// Function to list files in a folder
export const listFilesInFolder = async (folderName) => {
  try {
    console.log('PREFRENCE SHEETS ID', preferenceFolderID);
    console.log('Locating folder DayWiseSheets inside June...');
    const responseDayWiseSheets = await gapi.client.drive.files.list({
      q: `name = 'DayWiseSheets' and mimeType = 'application/vnd.google-apps.folder' and '${preferenceFolderID}' in parents and trashed = false`,
      fields: 'files(id, name)',
    });

    console.log('Response DayWiseSheets:', responseDayWiseSheets);
    console.log('Response DayWiseSheets JSON:', JSON.stringify(responseDayWiseSheets, null, 2));

    if (responseDayWiseSheets.result && responseDayWiseSheets.result.files && responseDayWiseSheets.result.files.length > 0) {
      const folderDayWiseSheetsId = responseDayWiseSheets.result.files[0].id;

      console.log('Listing sheets in the DayWiseSheets folder...');
      const sheetsResponse = await gapi.client.drive.files.list({
        q: `'${folderDayWiseSheetsId}' in parents and mimeType = 'application/vnd.google-apps.spreadsheet' and trashed = false`,
        fields: 'files(id, name, mimeType)',
      });

      console.log('Sheets Response:', sheetsResponse);
      console.log('Sheets Response JSON:', JSON.stringify(sheetsResponse, null, 2));

      if (sheetsResponse.result && sheetsResponse.result.files) {
        return sheetsResponse.result.files;
      } else {
        console.error('No sheets found in the DayWiseSheets folder');
        return [];
      }
    } else {
      console.error('DayWiseSheets folder not found inside June folder');
      return [];
    }
  } catch (error) {
    console.error('Error listing files:', error);
    return [];
  }
};

// Function to get sheet data
export const getSheetData = async (spreadsheetId, sheetName) => {
  try {
    console.log('Fetching sheet data...');
    console.log('Fetching sheet data with the following parameters:');
    console.log('Spreadsheet ID:', spreadsheetId);
    console.log(`Range: ${sheetName}!A:F`);
    const response = await gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId,
      range: `${sheetName}!A:F`,
    });

    console.log('Sheet Data Response:', response);
    return response.result.values;
  } catch (error) {
    console.error('Error fetching sheet data:', error);
    return null;
  }
};
