import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BiFoodTag } from "react-icons/bi";

const ListView = ({ sheetData }) => {
  const [expandedName, setExpandedName] = useState(null);

  const handleToggleExpand = (name) => {
    setExpandedName(expandedName === name ? null : name);
  };

  const getBgColor = (value) => {
    return value === 'OFF' ? 'bg-red-700' : 'bg-green-700';
  };

  const getPreferenceColor = (value) => {
    if (value === 'Veg') return 'text-green-500';
    if (value === 'Non Veg') return 'text-red-500';
    if (value === 'Base') return 'text-white';
  };

  // Sort sheet data alphabetically by name
  const sortedData = sheetData.sort((a, b) => (a[1] > b[1] ? 1 : -1));

  // Create a map of letters to their corresponding index in the sorted data
  const letterMap = sortedData.reduce((acc, row, index) => {
    const firstLetter = row[1][0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = index;
    }
    return acc;
  }, {});

  // Scroll to the specified letter
  const scrollToLetter = (letter) => {
    const index = letterMap[letter];
    if (index !== undefined) {
      document.getElementById(`name-${index}`).scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative my-5 flex justify-center w-full">
      <div className=" flex flex-row items-center ">
        <ul className="border mt-2  max-h-96 overflow-y-auto flex-grow">
          {sortedData.map((row, index) => (
            <li
              key={index}
              id={`name-${index}`}
              className="p-2 border-b cursor-pointer"
              onClick={() => handleToggleExpand(row[1])}
            >
              <div className="flex justify-between items-center font-bold">
                <span>{row[1]}</span>
                <BiFoodTag className={`text-2xl ${getPreferenceColor(row[5])}`} />
              </div>
              {expandedName === row[1] && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="mt-2 p-2 bg-gray-100  rounded-md"
                >
                  <div className={`text-center py-2 rounded-md my-2 text-white ${getBgColor(row[2])}`}>
                    <strong>Breakfast:</strong> {row[2]}
                  </div>
                  <div className={`text-center py-2 rounded-md my-2 text-white ${getBgColor(row[3])}`}>
                    <strong>Lunch:</strong> {row[3]}
                  </div>
                  <div className={`text-center py-2 rounded-md my-2 text-white ${getBgColor(row[4])}`}>
                    <strong>Dinner:</strong> {row[4]}
                  </div>
                  <div className={`flex justify-center text-center py-2 rounded-md my-2 text-black bg-white gap-2`}>
                    <strong className=''>{row[5]} </strong> 
                    <BiFoodTag className={`text-2xl ${getPreferenceColor(row[5])}`} />
                  </div>
                </motion.div>
              )}
            </li>
          ))}
        </ul>
        <div className="mx-1 w-6 mt-4">
          <ul className="flex flex-col items-center">
            {Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)).map(letter => (
              <li
                key={letter}
                className="cursor-pointer text-gray-500 hover:text-black text-xs"
                onClick={() => scrollToLetter(letter)}
              >
                {letter}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ListView;
