import React from 'react';
import { motion } from 'framer-motion';

const loadingContainerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
};

const spinnerVariants = {
  animate: {
    rotate: [0, 360],
    transition: {
      repeat: Infinity,
      duration: 1,
      ease: "linear"
    }
  }
};

const biniTextVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1.2,
      ease: "easeInOut"
    }
  }
};

const progressBarVariants = {
  hidden: { width: '0%' },
  visible: {
    width: '100%',
    transition: {
      duration: 3,
      ease: "linear"
    }
  }
};

const loadingTextVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: [0, 1, 0],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }
  }
};

const LoadingScreen = () => (
  <motion.div
    className="flex flex-col gap-8 items-center justify-center h-screen bg-black text-center"
    variants={loadingContainerVariants}
    initial="hidden"
    animate="visible"
    exit="exit"
  >
    <h1 className="text-cyan-400 text-4xl font-bold">Students Mess Preferences App</h1>
    <motion.a
      href="https://bineet.dev"
      className="text-gray-400 text-xl font-bold"
      variants={biniTextVariants}
      initial="hidden"
      animate="visible"
    >
      BY BINEET
    </motion.a>
    <motion.div
      className="w-12 h-12 border-4 border-t-4 border-t-cyan-400 border-gray-500 rounded-full"
      variants={spinnerVariants}
      animate="animate"
    />
    <motion.div
      className="text-cyan-400 text-xl mt-4"
      variants={loadingTextVariants}
      initial="hidden"
      animate="visible"
    >
      Loading...
    </motion.div>
    <motion.div
      className="w-full h-1 bg-cyan-400 mt-2"
      variants={progressBarVariants}
      initial="hidden"
      animate="visible"
    />
  </motion.div>
);

export default LoadingScreen;
